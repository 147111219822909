$(document).ready(function(){


    $('#registration-form').validator({language: 'en'});

    $(".country-select").select2();

    $('#addresses').on('change', function() {

        var url = phpvar.routeUrl;
        url = url.replace('%5Breplace%5D', $(this).val());
        $("body").css("cursor", "wait");

        $.getJSON(url, function( data ) {
            $.each( data, function (key, value) {

                $('#ship-addr-form').find('input[name="' + key + '"],select[name="' + key + '"]').val(value);

            });
            $('#ship-addr-form').find('input[name="_method"]').val('PUT');
            $("body").css("cursor", "default");


        });
    });

    $('#new-address').on('click', function() {
        $('#ship-addr-form').find(':text').not(':hidden').val('');
        $('#ship-addr-form').find('input[name="id"]').val('');
        $('#ship-addr-form').find('input[name="_method"]').val('POST');

    });

    $('.ship-addr-delete').on('click', function() {

        swal({
                title: phpvar.deleteTitle,
                text: phpvar.deleteText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: phpvar.deleteBtn,
                cancelButtonText: phpvar.deleteCancelBtn,
                closeOnConfirm: false
            },
            function(){
                var id = $('#addresses').val();
                var url = phpvar.deleteUrl;
                url = url.replace('%5Breplace%5D', id);
                window.location.href = url;
            });
    });


});