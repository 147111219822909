
$(document).ready(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.youtube-link').magnificPopup({type:'iframe'});
    $('.photo-link').magnificPopup({type: 'image'});

    $('.gallery-item-link').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        },
        // Delay in milliseconds before popup is removed
        removalDelay: 300,

        // Class that is added to popup wrapper and background
        // make it unique to apply your CSS animations just to this exact popup
        mainClass: 'mfp-fade'
    });

    new WOW().init();

    $('.photo-gallery-items').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
                enabled:true
            },
            // Delay in milliseconds before popup is removed
            removalDelay: 300,

            // Class that is added to popup wrapper and background
            // make it unique to apply your CSS animations just to this exact popup
            mainClass: 'mfp-fade'
        });
    });

    $('.video-gallery-items').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'iframe',
            gallery: {
                enabled:true
            },
            // Delay in milliseconds before popup is removed
            removalDelay: 300,

            // Class that is added to popup wrapper and background
            // make it unique to apply your CSS animations just to this exact popup
            mainClass: 'mfp-fade'
        });
    });


    $.datetimepicker.setLocale(phpvar.locale);

    $('.datetimepicker').datetimepicker({
        format:'d.m.Y'
    });
    $('.datepicker').datetimepicker({
        format:'d.m.Y',
        timepicker: false,
        mask:true
    });


    $('.gallery-items').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 4,
        variableWidth: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('.footer-items').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $("#home-scroll-down").click(function() {
        $('html, body').animate({
            scrollTop: $("#home-scroll-to").offset().top-60
        }, 1000);
    });


    function infinite(){

        $('.front-smoke').animate({backgroundPositionX:"1920px"},20000,'linear', function(){
             $(this).css({backgroundPositionX:'0px'});
        });


    }

   infinite();
   setInterval(infinite,20000);

    if ($('.members-photo').length) {


        var prevMemberPhoto = 0;
        var memberPhotoCount = $(".members-photo > div").length;

        function switchMemberPhoto() {
            do {
                var nextMemberPhoto = Math.floor(Math.random() * memberPhotoCount-1)+1;
            } while(nextMemberPhoto == prevMemberPhoto);
            prevMemberPhoto = nextMemberPhoto;


            $('.members-photo').children('div').addClass('opacity-hidden');
            $('.members-photo').children('div').eq(nextMemberPhoto).removeClass('opacity-hidden');



        }
        switchMemberPhoto();
        setInterval(switchMemberPhoto, 5000);

        var scrolled = 0;
        $(document).scroll(function() {
            if ($(document).scrollTop() >= 100) {

                if (scrolled == 0) {

                    switchMemberPhoto();
                    scrolled = 1;
                }
            }

        });

    }

    setTimeout(function(){
        $('body').addClass('loaded');

    }, 500);


    $('.welcome-logo-slider').slick({
        dots: false,
        infinite: true,
        speed: 800,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3500,
        arrows: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocus: false


    });



});

