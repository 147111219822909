/**
 * Created by Jerry on 19.10.2015.
 */

$(document).ready(function(){



    $('.reg-type').click(function() {
        if($('#type-company').is(':checked')) {
            $('.company-info-group').show('slow');
        }

        if ($('#type-personal').is(':checked')) {
            $('.company-info-group').slideUp();
        }
    });

    if ($('#type-personal').is(':checked')) {
        $('.company-info-group').hide();
    }



});